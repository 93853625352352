import React from 'react';

const PageOne = ({ show, dataFromlocalStorage, setValue }) => {
    const pageDisplayStyle = show
        ? {
              display: 'block',
          }
        : {
              display: 'none',
          };

    return (
        <div className="page-one page" id="page-one" style={pageDisplayStyle}>
            <p className="white-text form-title">
                I know, I know… forms are boring. But we need this information
                so we can expedite the onboarding call and so that the rest of
                the team at GlassHive can learn about you. This questionnaire
                will be used to gain information to help aid our creative team
                to gain insight into your company. Please take 15-30 minutes to
                fill this out to the best of your abilities, and we promise to
                deliver the best marketing materials we can.
            </p>
            <div>
                <div className="field name-field">
                    <label htmlFor="name">What’s your name?</label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={e => setValue('name', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage.name
                                : ''
                        }
                        required
                    />
                </div>
                <div className="field title-field">
                    <label htmlFor="title">What's your title?</label>
                    <input
                        type="text"
                        name="title"
                        id="title"
                        onChange={e => setValue('title', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage.title
                                : ''
                        }
                        required
                    />
                </div>
                <div className="field email-field">
                    <label htmlFor="work-email">
                        What's your work email address?
                    </label>
                    <input
                        type="text"
                        name="work-email"
                        id="work-email"
                        required
                        onChange={e => setValue('work-email', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage['work-email']
                                : ''
                        }
                    />
                </div>
                <div className="field">
                    <label htmlFor="co-name">What's your company name?</label>
                    <input
                        type="text"
                        name="co-name"
                        id="co-name"
                        required
                        onChange={e => setValue('co-name', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage['co-name']
                                : ''
                        }
                    ></input>
                </div>
                <div className="field">
                    <label htmlFor="site-url">What's your website URL?</label>
                    <input
                        type="text"
                        name="site-url"
                        id="site-url"
                        required
                        onChange={e => setValue('site-url', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage['site-url']
                                : ''
                        }
                    ></input>
                </div>
                <div className="field">
                    <label htmlFor="slogan">
                        Do you have a slogan or tagline? Type it below
                    </label>
                    <input
                        type="text"
                        name="slogan"
                        id="slogan"
                        onChange={e => setValue('slogan', e.target.value)}
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage['slogan']
                                : ''
                        }
                    ></input>
                </div>
                <div className="field">
                    <label htmlFor="phone-to-display-on-emails">
                        What phone number would you like to display on emails
                        and landing pages?
                    </label>
                    <input
                        type="text"
                        name="phone-to-display-on-emails"
                        id="phone-to-display-on-emails"
                        required
                        onChange={e =>
                            setValue(
                                'phone-to-display-on-emails',
                                e.target.value
                            )
                        }
                        defaultValue={
                            dataFromlocalStorage
                                ? dataFromlocalStorage[
                                      'phone-to-display-on-emails'
                                  ]
                                : ''
                        }
                    ></input>
                </div>
            </div>
            <div className="field comment-field">
                <label className="textarea-label" htmlFor="company-address">
                    Company Address (If more than one, add those as well)
                </label>
                <textarea
                    type="text"
                    name="company-address"
                    id="company-address"
                    required
                    onChange={e => setValue('company-address', e.target.value)}
                    defaultValue={
                        dataFromlocalStorage
                            ? dataFromlocalStorage['company-address']
                            : ''
                    }
                ></textarea>
            </div>
            <div>
                <div className="field">
                    <div className="radio-btns">
                        <p>Do you have a marketing list?</p>
                        <div className="two-col-radio">
                            <div className="radio">
                                <input
                                    type="radio"
                                    value="yes"
                                    id="yes"
                                    name="marketing-list"
                                    required
                                    onClick={e =>
                                        setValue('marketing-list', 'yes')
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'marketing-list'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="yes">Yes</label>
                            </div>
                            <div className="radio">
                                <input
                                    type="radio"
                                    value="no"
                                    id="no"
                                    name="marketing-list"
                                    onClick={e =>
                                        setValue('marketing-list', 'no')
                                    }
                                ></input>
                                <label htmlFor="no">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="ranking-container">
                        <p>
                            What’s the priority of solutions/services you would
                            like to market? Number 1 - 12. *Note: your priority
                            will determine the month it will be sent out.
                            Example: 1= Month 1 of your engagement. This cannot
                            be changed once set.
                        </p>
                        <div className="rank-options">
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="bdr"
                                    id="bdr"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue('bdr', e.target.value)
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage['bdr']
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="bdr">
                                    Backup and Disaster Recovery
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="unified-communication"
                                    id="unified-communication"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'unified-communication',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'unified-communication'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="unified-communication">
                                    Unified Communication
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="managed-services"
                                    id="managed-services"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'managed-services',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'managed-services'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="managed-services">
                                    Managed Services
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="virtualization"
                                    id="virtualization"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'virtualization',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'virtualization'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="virtualization">
                                    Virtualization
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="cybersecurity"
                                    id="cybersecurity"
                                    min="0"
                                    max="12"
                                    onChange={e =>
                                        setValue(
                                            'cybersecurity',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'cybersecurity'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="cybersecurity">
                                    Cybersecurity
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="consulting"
                                    id="consulting"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue('consulting', e.target.value)
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage['consulting']
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="consulting">Consulting</label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="cloud-computing"
                                    id="cloud-computing"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'cloud-computing',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'cloud-computing'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="cloud-computing">
                                    Cloud Computing
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="pro-services"
                                    id="pro-services"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue('pro-services', e.target.value)
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'pro-services'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="pro-services">
                                    Professional Services
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="managed-security"
                                    id="managed-security"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'managed-security',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'managed-security'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="managed-security">
                                    Managed Security
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="regulatory-compliance"
                                    id="regulatory-compliance"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue(
                                            'regulatory-compliance',
                                            e.target.value
                                        )
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage[
                                                  'regulatory-compliance'
                                              ]
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="regulatory-compliance">
                                    Regulatory Compliance
                                </label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="voip"
                                    id="voip"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue('voip', e.target.value)
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage['voip']
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="voip">VoIP</label>
                            </div>
                            <div className="rank-option-input">
                                <input
                                    type="number"
                                    name="it-support"
                                    id="it-support"
                                    min="0"
                                    max="12"
                                    required
                                    onChange={e =>
                                        setValue('it-support', e.target.value)
                                    }
                                    defaultValue={
                                        dataFromlocalStorage
                                            ? dataFromlocalStorage['it-support']
                                            : ''
                                    }
                                ></input>
                                <label htmlFor="it-support">IT Support</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageOne;
